/* Footer styles */

.columnMenuItem {
  /* skeleton */
  @apply mb-1;

  a,
  button {
    /* skeleton */
    @apply cursor-pointer select-none border-b border-solid outline-none transition-all ease-linear focus:outline-none;

    /* font */
    @apply font-default text-base font-normal leading-normal tracking-normal;

    /* color */
    @apply text-gray-one border-transparent bg-transparent hover:border-black hover:text-black;
  }
}

:global(.dark) .columnMenuItem {
  a,
  button {
    /* color */
    @apply text-gray-five hover:border-gray-four hover:text-gray-four border-transparent bg-transparent;
  }
}

.appsLink {
  /* skeleton */
  @apply -ml-2 w-full rounded-2xl;

  /* skeleton */
  @apply block cursor-pointer select-none outline-none transition-all ease-linear focus:outline-none;

  /* font */
  @apply font-default text-base font-normal leading-normal tracking-normal;

  /* color */
  @apply bg-gray-five hover:bg-gray-four hover:bg-opacity-50;
}

:global(.dark) .appsLink {
  /* color */
  @apply bg-gray-five hover:bg-gray-four;
}

.appsMenu {
  /* skeleton */
  @apply mt-8 flex flex-row px-2;
}

.socialMediaLink {
  /* skeleton */
  @apply mr-2;

  /* skeleton */
  @apply cursor-pointer select-none border-b border-solid outline-none transition-all ease-linear focus:outline-none;

  /* font */
  @apply font-default text-base font-normal leading-normal tracking-normal;

  /* color */
  @apply text-gray-one border-transparent bg-transparent hover:border-black hover:text-black;
}

:global(.dark) .socialMediaLink {
  /* color */
  @apply text-gray-five hover:border-gray-four hover:text-gray-four border-transparent bg-transparent;
}

.socialMediaMenu {
  /* skeleton */
  @apply mt-4 flex flex-row;
}

.columnMenu {
  /* skeleton */
  @apply mt-2 flex flex-col;
}

.column {
  /* skeleton */
  @apply flex flex-col py-4;
}

.innerContainer {
  @apply mx-auto grid w-full grid-cols-1 gap-x-20 px-4 pb-8 sm:grid-cols-2 sm:gap-x-40 md:grid-cols-3 md:gap-x-32 lg:grid-cols-5 lg:gap-x-8 xl:max-w-screen-xl xl:gap-x-24 2xl:max-w-screen-2xl 2xl:gap-x-24;
}

.container {
  /* skeleton */
  @apply flex w-full flex-col content-center items-center justify-center;

  /* color */
  @apply bg-white;
}

:global(.dark) .container {
  /* color */
  @apply bg-gray-zero;
}

.socketMenu {
  /* skeleton */
  @apply mb-4 flex flex-wrap content-center items-center justify-center lg:justify-start lg:last:justify-end;

  /* font */
  @apply font-default;

  /* color */
  @apply text-gray-one;

  /* custom */
  > div {
    @apply m-0 w-auto;

    button:last-of-type {
      @apply mr-0;
    }
  }
}

:global(.dark) .socketMenu {
  /* color */
  @apply text-gray-five;
}

.socketContainer {
  @apply mx-auto flex w-full flex-col-reverse justify-center px-4 pt-2 pb-8 lg:flex-row lg:justify-between xl:max-w-screen-xl 2xl:max-w-screen-2xl;
}
