/* GridSection */

.section {
  /* fade in */
  @apply animate-fade-in;

  /* skeleton */
  @apply -mt-1 -mb-1 flex h-full w-full flex-row flex-wrap justify-center;

  /* bg image */
  @apply bg-cover bg-center bg-no-repeat;
}

/* light  */
.divider-bottom-allports-1 {
  @apply bg-divider-bottom-allports-1;
}

.divider-bottom-allports-2 {
  @apply bg-divider-bottom-allports-2;
}

.divider-bottom-anakiwa-1 {
  @apply bg-divider-bottom-anakiwa-1;
}

.divider-bottom-desertstorm-1 {
  @apply bg-divider-bottom-desertstorm-1;
}

.divider-bottom-tuatuara-1 {
  @apply bg-divider-bottom-tuatuara-1;
}

.divider-bottom-rouge-1 {
  @apply bg-divider-bottom-rouge-1;
}

.divider-bottom-tawnyport-1 {
  @apply bg-divider-bottom-tawnyport-1;
}

.divider-top-desertstorm-1 {
  @apply bg-divider-top-desertstorm-1;
}

.divider-top-tuatuara-1 {
  @apply bg-divider-top-tuatuara-1;
}

.col-1 {
  @apply grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1;
}

.col-2 {
  @apply grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2;
}

.col-3 {
  @apply grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3;
}

.col-4 {
  @apply grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4;
}

.col-5 {
  @apply grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5;
}

.top {
  @apply items-start;
}

.center {
  @apply items-center;
}

.bottom {
  @apply items-end;
}

/* color */

.color-white {
  @apply bg-white;
}

.color-energyYellow {
  @apply bg-tertiary-four;
}

.color-allports {
  @apply bg-primary;
}

.color-desertStorm {
  @apply bg-gray-five;
}

.color-anakiwa {
  @apply bg-secondary;
}

.color-tuatara {
  @apply bg-black;
}

.color-java {
  @apply bg-tertiary-two bg-opacity-25;
}

:global(.dark) .color-white {
  @apply bg-gray-zero;
}

:global(.dark) .color-energyYellow {
  @apply bg-tertiary-four;
}

:global(.dark) .color-allports {
  @apply bg-primary-dark;
}

:global(.dark) .color-desertStorm {
  @apply bg-black;
}

:global(.dark) .color-anakiwa {
  @apply bg-secondary;
}

:global(.dark) .color-tuatara {
  @apply bg-black;
}

:global(.dark) .color-java {
  @apply bg-black bg-opacity-100;
}

/* dark */
:global(.dark) .divider-bottom-allports-1-dark {
  @apply bg-divider-bottom-allports-1;
}

:global(.dark) .divider-bottom-allports-2-dark {
  @apply bg-divider-bottom-allports-2;
}

:global(.dark) .divider-bottom-anakiwa-1-dark {
  @apply bg-divider-bottom-anakiwa-1;
}

:global(.dark) .divider-bottom-desertstorm-1-dark {
  @apply bg-divider-bottom-desertstorm-1;
}

:global(.dark) .divider-bottom-tuatuara-1-dark {
  @apply bg-divider-bottom-tuatuara-1;
}

:global(.dark) .divider-bottom-rouge-1-dark {
  @apply bg-divider-bottom-rouge-1;
}

:global(.dark) .divider-bottom-tawnyport-1-dark {
  @apply bg-divider-bottom-tawnyport-1;
}

:global(.dark) .divider-top-desertstorm-1-dark {
  @apply bg-divider-top-desertstorm-1;
}

:global(.dark) .divider-top-tuatuara-1-dark {
  @apply bg-divider-top-tuatuara-1;
}

.container {
  /* skeleton */
  @apply my-0 mx-auto grid w-full px-2 lg:px-0;

  /* container */
  @apply max-w-full lg:max-w-screen-xl 2xl:max-w-screen-2xl;
}
