/* EarlyAccessForm */

.storyliner-early-access-form-privacy-container {
  @apply mt-4 flex w-full content-center items-center justify-center;

  svg {
    @apply mr-1 mb-2 h-4 w-4 text-black;
  }
}

:global(.dark) .storyliner-early-access-form-privacy-container {
  @apply text-gray-five;
}

.storyliner-early-access-form-container {
  /* skeleton */
  @apply mt-4 w-full;
}

.storyliner-early-access-form {
  @apply z-10 my-0 mx-auto flex w-full max-w-prose flex-col justify-center;
}

.storyliner-early-access-form-input-container {
  @apply flex flex-col justify-center;

  input {
    @apply h-12 w-full;
  }

  button {
    @apply mt-2.5 h-12 w-full;
  }
}

.storyliner-early-access-form-error-container {
  @apply mt-2 flex;
}
