/* Section styles */

.section {
  /* fade in */
  @apply animate-fade-in;

  /* skeleton */
  @apply flex h-auto w-full flex-row flex-wrap justify-center;

  /* color */
  @apply bg-white;

  &.energyYellow {
    @apply bg-tertiary-three bg-opacity-50;
  }

  &.allports {
    @apply bg-tertiary-fourteen;
  }

  &.desertStorm {
    @apply bg-gray-five;
  }

  &.anakiwa {
    @apply bg-secondary bg-opacity-50;
  }

  &.java {
    @apply bg-tertiary-two bg-opacity-25;
  }

  &.tuatara {
    @apply bg-black;
  }

  /* bg image */
  @apply bg-cover bg-no-repeat;

  /* light  */
  &.divider-bottom-allports-1 {
    @apply bg-divider-bottom-allports-1;
  }

  &.divider-bottom-allports-2 {
    @apply bg-divider-bottom-allports-2;
  }

  &.divider-bottom-anakiwa-1 {
    @apply bg-divider-bottom-anakiwa-1;
  }

  &.divider-bottom-desertstorm-1 {
    @apply bg-divider-bottom-desertstorm-1;
  }

  &.divider-bottom-tuatuara-1 {
    @apply bg-divider-bottom-tuatuara-1;
  }

  &.divider-bottom-rouge-1 {
    @apply bg-divider-bottom-rouge-1;
  }

  &.divider-bottom-tawnyport-1 {
    @apply bg-divider-bottom-tawnyport-1;
  }

  &.divider-top-desertstorm-1 {
    @apply bg-divider-top-desertstorm-1;
  }

  &.divider-top-tuatuara-1 {
    @apply bg-divider-top-tuatuara-1;
  }
}

:global(.dark) .section {
  /* color */
  @apply bg-gray-zero;

  &.energyYellow {
    @apply bg-tertiary-thirteen;
  }

  &.allports {
    @apply bg-tertiary-thirteen;
  }

  &.desertStorm {
    @apply bg-black;
  }

  &.anakiwa {
    @apply bg-black;
  }

  &.java {
    @apply bg-black bg-opacity-100;
  }

  &.tuatara {
    @apply bg-black;
  }

  /* dark */
  &.divider-bottom-allports-1-dark {
    @apply bg-divider-bottom-allports-1;
  }

  &.divider-bottom-allports-2-dark {
    @apply bg-divider-bottom-allports-2;
  }

  &.divider-bottom-anakiwa-1-dark {
    @apply bg-divider-bottom-anakiwa-1;
  }

  &.divider-bottom-desertstorm-1-dark {
    @apply bg-divider-bottom-desertstorm-1;
  }

  &.divider-bottom-tuatuara-1-dark {
    @apply bg-divider-bottom-tuatuara-1;
  }

  &.divider-bottom-rouge-1-dark {
    @apply bg-divider-bottom-rouge-1;
  }

  &.divider-bottom-tawnyport-1-dark {
    @apply bg-divider-bottom-tawnyport-1;
  }

  &.divider-top-desertstorm-1-dark {
    @apply bg-divider-top-desertstorm-1;
  }

  &.divider-top-tuatuara-1-dark {
    @apply bg-divider-top-tuatuara-1;
  }
}

.section-container {
  /* skeleton */
  @apply my-0 mx-auto flex w-full flex-wrap justify-center px-2 lg:px-0;

  /* container */
  @apply max-w-full lg:max-w-screen-xl 2xl:max-w-screen-2xl;

  &.is-top {
    @apply items-start;
  }

  &.is-middle {
    @apply items-center;
  }

  &.is-bottom {
    @apply items-end;
  }

  &.is-row-reverse-mobile {
    @apply flex-row-reverse;
  }

  &.is-row-mobile {
    @apply flex-row;
  }

  &.is-column-reverse-mobile {
    @apply flex-col-reverse;
  }

  &.is-column-mobile {
    @apply flex-col;
  }

  &.is-row-reverse-tablet {
    @apply sm:flex-row-reverse lg:flex-row;
  }

  &.is-row-tablet {
    @apply sm:flex-row lg:flex-row;
  }

  &.is-column-reverse-tablet {
    @apply sm:flex-col-reverse lg:flex-row;
  }

  &.is-column-tablet {
    @apply sm:flex-col lg:flex-row;
  }

  &.is-paddingless {
    @apply !p-0;
  }
}
