/* StorylinerImage styles */

.image {
  /* skeleton */
  @apply relative w-full animate-fade-in shadow-none;

  /* round */
  &.is-rounded {
    @apply rounded-2xl;

    source,
    picture,
    img {
      @apply rounded-2xl;
    }
  }

  /* filter */
  &.is-white {
    filter: brightness(0%) invert(95%) !important;
  }

  &.is-black {
    @apply brightness-0 filter;
  }

  &.has-shadow {
    @apply shadow-default;
  }

  &.white-bg {
    @apply bg-white;
  }
}

:global(.dark) .image {
  @apply shadow-none;

  /* filter */
  &.is-white {
    filter: brightness(0%) invert(95%) !important;
  }

  &.is-black {
    @apply invert;
  }

  &.has-shadow {
    @apply shadow-default-dark;
  }

  &.white-bg {
    @apply bg-white;
  }
}
