/* EarlyAccessModal */

.storyliner-early-access-modal-form-container {
  @apply flex flex-row justify-center w-full;
}

.storyliner-early-access-modal-header-container {
  @apply flex flex-col items-center h-full w-full;
}

.storyliner-early-access-modal-content {
  @apply flex flex-col justify-center p-2;
}
