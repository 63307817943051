/* Column */

.column {
  @apply flex w-full justify-center;

  &.col-full {
    @apply max-w-full;
  }

  &.col-xl {
    @apply max-w-screen-xl;
  }

  &.col-lg {
    @apply max-w-screen-lg;
  }

  &.col-ml {
    @apply max-w-[900px];
  }

  &.col-prose {
    @apply max-w-prose;
  }

  &.col-md {
    @apply max-w-screen-md;
  }

  &.col-sm {
    @apply max-w-screen-sm;
  }

  &.col-5 {
    @apply max-w-[49%] sm:max-w-[49%] md:max-w-[24%] lg:max-w-[24%] xl:max-w-[19%];
  }

  &.col-4 {
    @apply max-w-[100%] sm:max-w-[49%] md:max-w-[32%] lg:max-w-[24%] xl:max-w-[24%];
  }

  &.col-3 {
    @apply max-w-[100%] sm:max-w-[50%] md:max-w-[45%] lg:max-w-[32%] xl:max-w-[30%];
  }

  &.col-2 {
    @apply max-w-[100%] sm:max-w-[49%] md:max-w-[49%] lg:max-w-[49%] xl:max-w-[47%];
  }

  &.col-1 {
    @apply max-w-[100%] sm:max-w-[100%] md:max-w-[100%] lg:max-w-[100%] xl:max-w-[100%];
  }
}

.container {
  @apply flex-1 p-4;

  &.is-paddingless {
    @apply !p-0;
  }
}
